import 'alpinejs'
import 'lazysizes';

var scrollPosition = window.scrollY;
var logoContainer = document.getElementsByClassName('nav')[0];
var taglineMobile = document.getElementsByClassName('tagline-mobile')[0];
var navMobile = document.getElementsByClassName('nav-mobile')[0];

window.addEventListener('scroll', function() {
    scrollPosition = window.scrollY;

    if (scrollPosition >= 60) {
        logoContainer.classList.add('scrolled');
        navMobile.classList.add('scrolled');
        taglineMobile.classList.add('hidden');
    } else {
        logoContainer.classList.remove('scrolled');
        navMobile.classList.remove('scrolled');
        taglineMobile.classList.remove('hidden');
    }
});

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
    import.meta.hot.accept(() => {
      console.log("HMR")
    });
}